<template lang="pug">
.ConfirmDataAuth
  .banderaSvgMobile
  Modal(:header="true" :goBack="goBack" :disabled="charging")
    template(#body)
      .d-flex.justify-content-center
        img(
          src="@/assets/mobileFSFB/confirm_data.png"
          alt="confirm_data"
        ).image-confirm_data
        h2.text-confirm_data.ml-3 Confírmanos tus datos
      article.pl-2.pt-3.containerData.mb-4.pb-1(v-if="docNumber")
        p.mb-1.containerFontModal
          strong.strongFont Nombre:
          span.ml-1 {{ fullname }}
        p.mb-1.containerFontModal
          strong.strongFont Documento:
          span.ml-1 {{ docType }}
        p.mb-1.containerFontModal
          strong.strongFont Número:
          span.ml-1 ******{{ docNumber.substring(4) }}
      article.pl-2.pt-3.containerData.mb-4.pb-1(v-else)
        .box-error
          .pt-5.m-5
            AlertOutline(:size="50").alert
          p(style="color: gray;").text-center.mb-3 No encontramos información del usuario
      .d-flex.justify-content-between.pt-3.pb-4
        button(
          @click="goBack"
          :disabled="charging"
        ).buttonBack.px-4.py-2 Reingresar
        button(
          v-if="docNumber"
          @click="onContinue"
          :disabled="charging"
        ).buttonConfirm.px-4.py-2
          span(v-if="!charging") Confirmar
          span(v-else)
            MiniSpinner
</template>

<script>
import { mapActions, mapState } from "vuex";
import AlertOutline from "mdi-vue/AlertOutline";

export default {
  name: "ConfirmDataAuth",

  components: {
    Modal: () => import("./components/Modal.vue"),
    MiniSpinner: () => import("../components/MiniSpinner"),
    AlertOutline
  },

  computed: {
    ...mapState({
      charging: state => state.mobileFSFB.charging,
      docInfo: state => state.mobileFSFB.docInfo,
      currentUser: state => state.mobileFSFB.currentUser
    }),

    fullname() {
      return this.currentUser
        ? this.currentUser.names + ' ' +
            (this.currentUser.last_name || '') + ' ' +
            (this.currentUser.mother_last_name || '')
        : "";
    },

    docType() {
      return this.currentUser
        ? this.currentUser.tdoc
          ? this.currentUser.tdoc.detail
          : "N/R"
        : "";
    },

    idType() {
      return this.docInfo?.TipoIdentificacion || "";
    },

    docNumber() {
      return this.currentUser ? this.currentUser.doc : "";
    },

    number() {
      return "223445***";
    },

    toSend() {
      return {
        ...this.docInfo,
        CodigoOficina: this.$route.params.code + "?area=admisiones_1"
      };
    }
  },

  methods: {
    ...mapActions({
      goToView: "mobileFSFB/goToView",
      authenticate: "mobileFSFB/authenticate",
      sendTracking: "mobileFSFB/handleTracking"
    }),

    goBack() {
      this.goToView({ view: "Home" });
    },

    async onContinue() {
      // await this.authenticate(this.toSend);
      this.goToView({ view: "Procedure" });
      // nextComponent.view = "Procedure";

      // tracking
      setTimeout(() => {
        let origin = "mobile_fsfb";
        let auth = this.error
          ? " autentitacion-no-cliente"
          : "autenticacion-cliente";
        let trackingData = {
          uuid: window.person.id,
          accion: auth,
          debmedia_turn_code: "",
          url_origen: window.location.href,
          origen: origin + this.currentSite?.type,
          mensajes: ""
        };

        this.sendTracking(trackingData);
      }, 2000);
    }
  }
};
</script>

<style lang="scss">
.alert {
  fill: #f00;
  color: #f00;
  text-align: center;
  max-width: 147px;
  display: block;
  margin: auto;
}

.dk {
  max-width: 350px;
  display: block;
  margin: auto;
}
</style>
